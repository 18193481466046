import { CreateControllerFn } from '@wix/yoshi-flow-editor';

import { EGroupPartition } from 'api/groups/types';

import { LayoutType } from 'settings/consts';
import {
  GROUPS_SELECTOR_TYPE,
  groupsListWidgetSettingsParams as settingsParams,
} from 'settings/groups-list-widget';

import { WixGroupsController } from '../../controller';

const createController: CreateControllerFn = async (params) => {
  const {
    flowAPI: { settings, experiments, controllerConfig },
  } = params;

  const controller = new WixGroupsController(params);

  controller.handleTransition = handleTransition;

  const { wixCodeApi } = controllerConfig;
  wixCodeApi.user.onLogin(handleTransition);

  const limit = experiments.enabled('specs.groups.CustomGroupListWidget')
    ? settings.get(settingsParams.groupListLayout) === LayoutType.grid
      ? 6
      : 5
    : settings.get(settingsParams.numberGroups);

  return controller;

  async function handleTransition() {
    await Promise.all([
      controller.vm.groups$.query({
        namespace: EGroupPartition.ALL,
        partition: EGroupPartition.ALL,
        limit,
        sort: settings.get(settingsParams.sortBy),
        groupIds: getGroupIdsToShow(),
      }),
      controller.vm.application$.fetchUserProfile(),
    ]);

    return {};
  }

  function getGroupIdsToShow() {
    if (!experiments.enabled('specs.groups.CustomGroupListWidget')) {
      return undefined;
    }
    switch (settings.get(settingsParams.groupsSelectorType)) {
      case GROUPS_SELECTOR_TYPE.ALL:
        return undefined;
      case GROUPS_SELECTOR_TYPE.CUSTOM:
        try {
          return JSON.parse(settings.get(settingsParams.groupIdsToShow));
        } catch (e: any) {
          return [];
        }
    }
  }
};

export default createController;
